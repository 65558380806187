import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { HeroStyles } from '../styles/GlobalStyles'
import SEO from '../components/SEO'
import BackgroundImage from '../components/BackgroundImage'
import bemWordmark from '../assets/logo/bem-wordmark.png'

const VanStyles = styled.div`
  padding: var(--spacing);

  p:first-of-type {
    font-size: 1.25rem;
    font-weight: bold;
  }

  p:not(:first-of-type) {
    font-size: 1rem;
  }
`

export default function VanPage({ data }) {
  // Hero image
  const vanImage = data?.file?.childImageSharp?.gatsbyImageData

  return (
    <>
      <SEO title="Van Rental" />
      <BackgroundImage imageData={vanImage} underHeader fullHeight>
        <HeroStyles>
          <div>
            <img className="logo" src={bemWordmark} alt="Bird's Eye Maine" />
            <h1>Van Rental</h1>
            <p>Rent your own camper van and explore Maine</p>
          </div>
        </HeroStyles>
      </BackgroundImage>
      <VanStyles>
        <p>We are sorry for any inconvenience this next sentence is going to cause...</p>
        <p>Our van rental section is going through a redesign and will not be available for now.</p>
        <p>Our van will also be featured on the Outdoorsy phone app.</p>
        <p>Thank you for your patience &mdash; let's go boating!</p>
      </VanStyles>
    </>
  )
}

export const query = graphql`
  {
    file(sourceInstanceName: { eq: "background" }, name: { eq: "van" }) {
      name
      childImageSharp {
        id
        gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 90, formats: [AUTO, WEBP])
      }
    }
  }
`
